<template>
  <div>
    <div class="bgBox">
      <el-carousel arrow="never" indicator-position="none">
        <el-carousel-item v-for="img in bglist" :key="img.id">
          <img class="img" :src="img.url" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="bgTitle">
        <div>智慧一体化云平台</div>
        <div>一个提供高效的管理解决方案平台</div>
      </div>
    </div>
    <div >
      <div class="mainBox">
        <div class="mainauto">
          <div class="title">他们正在使用智慧一体化云平台</div>
          <div class="subtitle">
            100,0家企业的共同选择，提供专业前沿的资产管理和服务。
          </div>
          <ul class="brandType">
            <li v-for="item in brandList" :key="item.id">
              <span
                :class="{ active: curIndex == item.id }"
                @click="curIndex = item.id"
                >{{ item.name }}</span
              >
            </li>
          </ul>
          <div class="brand_bigbox">
            <div
              class="brandCard"
              :style="{
                transform: `translate(-${(curIndex - 1) * 1296}px)`,
                transition: `transform ${curIndex * 0.1}s linear 0s`,
              }"
            >
              <ul
                class="brandBox"
                :class="{ brandActive: curIndex == brand.id }"
                v-for="brand in brandList"
                :key="brand.id"
              >
                <li v-for="brandname in brand.imgList" :key="brandname.index">
                  <img :src="brandname.url" alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mainauto">
          <div class="title">我们近期正在服务的客户</div>
          <div class="subtitle">近期客户案例</div>
          <div class="customerBox">
            <div class="customerTop"></div>
            <div class="customerImg">
              <img src="@/assets/case/customer.png" alt="" />
            </div>
          </div>
        </div>
        
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "case-page",
  data() {
    return {
      curIndex: 1,
      bglist: [{ id: 0, url: require("@/assets/case/bg1.png") }],
      brandList: [
        {
          name: "园区/办公",
          id: 1,
          imgList: [
            { url: require("@/assets/brand/park/logo_dongyi.png") },
            { url: require("@/assets/brand/park/logo_huantu.png") },
            { url: require("@/assets/brand/park/logo_huitian.png") },
            { url: require("@/assets/brand/park/logo_ykgc.png") },
            { url: require("@/assets/brand/park/logo_zkds.png") },
          ],
        },
        {
          name: "教育",
          id: 2,
          imgList: [
            { url: require("@/assets/brand/education/logo_biss.png") },
            { url: require("@/assets/brand/education/logo_boston.png") },
            { url: require("@/assets/brand/education/logo_gsx.png") },
            { url: require("@/assets/brand/education/logo_gzcj.png") },
            { url: require("@/assets/brand/education/logo_hhsw.png") },
            { url: require("@/assets/brand/education/logo_hpjy.png") },
            { url: require("@/assets/brand/education/logo_jrjy.png") },
            { url: require("@/assets/brand/education/logo_spfls.png") },
            { url: require("@/assets/brand/education/logo_ycis.png") },
            { url: require("@/assets/brand/education/logo_yljy.png") },
            { url: require("@/assets/brand/education/logo_ywiek.png") },
            { url: require("@/assets/brand/education/logo_zrjy.png") },
          ],
        },
        {
          name: "地产/酒店",
          id: 3,
          imgList: [
            { url: require("@/assets/brand/property/logo_dac.png") },
            { url: require("@/assets/brand/property/logo_gakg.png") },
            { url: require("@/assets/brand/property/logo_hqc.png") },
            { url: require("@/assets/brand/property/logo_hrzd.png") },
            { url: require("@/assets/brand/property/logo_hyjt.png") },
            { url: require("@/assets/brand/property/logo_lancheng.png") },
            { url: require("@/assets/brand/property/logo_lianjia.png") },
            { url: require("@/assets/brand/property/logo_lvdikonggu.png") },
            { url: require("@/assets/brand/property/logo_wanda.png") },
            { url: require("@/assets/brand/property/logo_wawj.png") },
            {
              url: require("@/assets/brand/property/logo_wukongzhaofang.png"),
            },
            {
              url: require("@/assets/brand/property/logo_zhongguotiejian.png"),
            },
          ],
        },
        {
          name: "生产制造",
          id: 4,
          imgList: [
            { url: require("@/assets/brand/production/logo-xiexin.png") },
            { url: require("@/assets/brand/production/logo_asia.png") },
            { url: require("@/assets/brand/production/logo_ecovacs.png") },
            { url: require("@/assets/brand/production/logo_lux.png") },
            { url: require("@/assets/brand/production/logo_omron.png") },
            { url: require("@/assets/brand/production/logo_panasonic.png") },
            { url: require("@/assets/brand/production/logo_suntech.png") },
            { url: require("@/assets/brand/production/logo_tata.png") },
            { url: require("@/assets/brand/production/logo_tengen.png") },
            { url: require("@/assets/brand/production/logo_whgx.png") },
          ],
        },
        {
          name: "金融",
          id: 5,
          imgList: [
            { url: require("@/assets/brand/finance/logo_china_bank.png") },
            { url: require("@/assets/brand/finance/logo_chinare.png") },
            { url: require("@/assets/brand/finance/logo_citic.png") },
            { url: require("@/assets/brand/finance/logo_gkh.png") },
            { url: require("@/assets/brand/finance/logo_gsbdc.png") },
            { url: require("@/assets/brand/finance/logo_htf.png") },
            { url: require("@/assets/brand/finance/logo_wdw.png") },
            { url: require("@/assets/brand/finance/logo_weibao.png") },
            { url: require("@/assets/brand/finance/logo_yrd.png") },
            { url: require("@/assets/brand/finance/logo_zgpa.png") },
            { url: require("@/assets/brand/finance/logo_zgtp.png") },
            { url: require("@/assets/brand/finance/logo_zhongyirenshou.png") },
          ],
        },
        {
          name: "互联网",
          id: 6,
          imgList: [
            { url: require("@/assets/brand/internet/logo_360.png") },
            { url: require("@/assets/brand/internet/logo_caocao.png") },
            { url: require("@/assets/brand/internet/logo_dada.png") },
            { url: require("@/assets/brand/internet/logo_dedao.png") },
            {
              url: require("@/assets/brand/internet/logo_horizonroboties.png"),
            },
            { url: require("@/assets/brand/internet/logo_jddj.png") },
            { url: require("@/assets/brand/internet/logo_jrtt.png") },
            { url: require("@/assets/brand/internet/logo_maimai.png") },
            { url: require("@/assets/brand/internet/logo_qmxc.png") },
            { url: require("@/assets/brand/internet/logo_scsj.png") },
            { url: require("@/assets/brand/internet/logo_weimob.png") },
            { url: require("@/assets/brand/internet/logo_wifi.png") },
            { url: require("@/assets/brand/internet/logo_xiaopeng.png") },
            { url: require("@/assets/brand/internet/logo_xiaoshouyi.png") },
            { url: require("@/assets/brand/internet/logo_yitu.png") },
            { url: require("@/assets/brand/internet/logo_zhihu.png") },
          ],
        },
        {
          name: "零售",
          id: 7,
          imgList: [
            { url: require("@/assets/brand/retail/logo_LVHM.png") },
            { url: require("@/assets/brand/retail/logo_bsd.png") },
            { url: require("@/assets/brand/retail/logo_coca_cola.png") },
            { url: require("@/assets/brand/retail/logo_deji.png") },
            { url: require("@/assets/brand/retail/logo_deli.png") },
            { url: require("@/assets/brand/retail/logo_hly.png") },
            { url: require("@/assets/brand/retail/logo_holiland.png") },
            { url: require("@/assets/brand/retail/logo_hxegt.png") },
            { url: require("@/assets/brand/retail/logo_jn.png") },
            { url: require("@/assets/brand/retail/logo_mengniu.png") },
            { url: require("@/assets/brand/retail/logo_msl.png") },
            { url: require("@/assets/brand/retail/logo_redstar.png") },
            { url: require("@/assets/brand/retail/logo_tongrentang.png") },
            { url: require("@/assets/brand/retail/logo_xl.png") },
          ],
        },
        {
          name: "集团",
          id: 8,
          imgList: [
            { url: require("@/assets/brand/group/logo_dfhk.png") },
            { url: require("@/assets/brand/group/logo_hn.png") },
            { url: require("@/assets/brand/group/logo_jinmao.png") },
            { url: require("@/assets/brand/group/logo_linde.png") },
            { url: require("@/assets/brand/group/logo_sto.png") },
            { url: require("@/assets/brand/group/logo_suning.png") },
            { url: require("@/assets/brand/group/logo_wanda_dalian.png") },
            { url: require("@/assets/brand/group/logo_zglt.png") },
            { url: require("@/assets/brand/group/logo_zgyd.png") },
            { url: require("@/assets/brand/group/logo_zxtx.png") },
          ],
        },
        {
          name: "医疗",
          id: 9,
          imgList: [
            {
              url: require("@/assets/brand/medicine/logo_Boston-Scientific.png"),
            },
            { url: require("@/assets/brand/medicine/logo_Densply.png") },
            { url: require("@/assets/brand/medicine/logo_Medtronic.png") },
            { url: require("@/assets/brand/medicine/logo_aslk.png") },
            { url: require("@/assets/brand/medicine/logo_bdyl.png") },
            { url: require("@/assets/brand/medicine/logo_dxy.png") },
            { url: require("@/assets/brand/medicine/logo_fx.png") },
            { url: require("@/assets/brand/medicine/logo_gilead.png") },
            { url: require("@/assets/brand/medicine/logo_ml.png") },
            { url: require("@/assets/brand/medicine/logo_smf.png") },
            { url: require("@/assets/brand/medicine/logo_wlys.png") },
            { url: require("@/assets/brand/medicine/logo_ymkd.png") },
            { url: require("@/assets/brand/medicine/logo_zgyy.png") },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.bgBox {
  width: 100%;
  position: relative;
}
.bgBox >>> .el-carousel__container {
  height: 700px;
}
.img {
  width: 100%;
  height: 100%;
}
.bgTitle {
  min-width: 700px;
  position: absolute;
  color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  line-height: 50px;
  text-align: center;
}
.bgTitle > div:nth-child(1) {
  font-size: 48px;
}
.bgTitle > div:nth-child(2) {
  font-size: 14px;
}
.mainBox {
  overflow: hidden;
  padding-bottom: 180px;
}
.mainauto {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
}
.title {
  font-size: 38px;
  color: #404040;
  padding-top: 56px;
}
.subtitle {
  padding: 40px 0;
  font-size: 18px;
  color: #666;
}
.brandType {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding: 40px 0 80px;
}
.brandType li {
  width: calc(100% / 3);
  margin: 5px 0 20px;
}
.brandType li span {
  font-size: 20px;
  padding: 7px 10px;
  display: inline-block;
  border: solid 1px transparent;
  cursor: pointer;
  border-radius: 30px;
  min-width: 122px;
  color: #404040;
}
.brandType li .active {
  border-color: #668eff;
  color: #668eff;
}
.brand_bigbox {
  width: 100%;
}
.brandCard {
  width: 11568px;
  position: relative;
  transform: translate(0);
  transition: transform 3s linear 0s;
}
.brandBox {
  width: 1200px;
  padding: 80px 0;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  float: left;
  margin-right: 96px;
  position: relative;
}
.brandBox:last-child {
  margin-right: 0;
}
.brandBox ::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 16px;
  background: transparent;
  top: 0;
  left: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.brandActive ::before {
 background: linear-gradient(-135deg, #bfd9ff 0%, #c8f7fe 70%);
}
.brandBox li {
  float: left;
  width: calc(100% / 4);
  margin: 20px 0 25px;
}
.brandBox li img {
  max-width: 200px;
  margin: 0 auto;
  display: block;
}
.customerBox {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.customerBox .customerTop {
  width: 100%;
  height: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: linear-gradient(-135deg, #bfd9ff 0%, #c8f7fe 70%);
}
.customerBox .customerImg {
  width: 100%;
  padding: 60px;
}
.customerBox .customerImg img {
  width: 100%;
}
</style>